import React, { useContext, useEffect } from "react";
import axios from "axios"; // Import axios
import styles from "./index.module.scss";
import { SEND_ENUM } from "../../enums/sendEnum";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { AppContext } from "../../context";
import PayezyIcon from "../../assets/PayezyIcon.svg";
import RemitlyIcon from "../../assets/RemitlyIcon.svg";
import WiselyIcon from "../../assets/WiseIcon.svg";
import ToolttipIcon from "../../assets/ToolTipIcon.svg";
import WesternUnionIcon from "../../assets/westernUnionImage.svg";
import AOS from "aos";
import downArrow from "../../assets/downArrow.svg";
import ofxIcon from "../../assets/ofxIcon.svg";
import InstaremIcon from "../../assets/InstaramIcon.svg";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css"; // Import the default styles
import "../../components/ReactLoading/reactLoading.css";

const BestInMarket = ({
  usdToInrExRate,
  exchangeRateData,
  setExchangeRateData,
  transferFeeData,
  setTransferFeeData,
}) => {
  const { isMobile } = useContext(AppContext);

  useEffect(() => {
    AOS.init({});
  }, []);

  const THOUSAND = 1000;
  const TWO_FIXED_TWO = 2;
  const PAYEZY_TRANSFER_FEE = 0.0;

  const renderTooltipPayezy = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle}>
      Mid-market Rate
    </Tooltip>
  );
  const renderTooltipWesternUnion = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle}>
      Less than Mid-Market Rate
    </Tooltip>
  );
  const renderTooltipWise = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle}>
      Mid-Market Rate
    </Tooltip>
  );
  const renderTooltipRemitely = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle}>
      Less than Mid-Market Rate
    </Tooltip>
  );
  const renderTooltipInstarem = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle}>
      Less than Mid-Market Rate
    </Tooltip>
  );
  const renderTooltipOfx = (props) => (
    <Tooltip {...props} className={styles.toolTipStyle}>
      Less than Mid-Market Rate
    </Tooltip>
  );
  const renderTooltipTrueRate = (props) => (
    <Tooltip {...props} className={styles.toolTipStyleTrueValue}>
      True Rate is the net exchange rate at which the beneficiary receives the
      value of a currency transfer (e.g., USD to INR), taking into account any
      fees, charges, or deductions. It reflects the true cost of the transaction
      for the recipient after all applied fees.
    </Tooltip>
  );
  //Function to calculate recipient gets
  function calculateRecipientGetsValue(exchangeRateData) {
    const fixedExchangeRateData =
      Number(exchangeRateData).toFixed(TWO_FIXED_TWO);
    const result = (fixedExchangeRateData * THOUSAND).toFixed(TWO_FIXED_TWO);
    return result;
  }
  //Function to calculate trur value
  function calculateTrueValue(exchangeRateData, totalCost) {
    const result = ((THOUSAND * exchangeRateData) / totalCost).toFixed(
      TWO_FIXED_TWO
    );
    return result;
  }

  //Function to calculate total cost
  function calculateTotalCost(transferFeeData) {
    const result = THOUSAND + transferFeeData;
    return result;
  }

  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        const copperxResponse = await axios.get(
          "https://proxy.cors.sh/https://copperx.io/api/comparisons",
          {
            params: {
              sendAmount: THOUSAND,
              sourceCurrency: "USD",
              targetCurrency: "INR",
            },
            headers: {
              "x-cors-api-key": process.env.REACT_APP_CORS_API_KEY,
            },
          }
        );
        const copperxData = copperxResponse.data.providers;

        // Fetch Wise exchange rate from Wise API
        const wiseResponse = await axios.get(
          "https://proxy.cors.sh/https://api.wise.com/v1/rates/live?source=USD&target=INR",
          {
            headers: {
              "x-cors-api-key": process.env.REACT_APP_CORS_API_KEY,
            },
          }
        );
        const wiseData = wiseResponse.data;
        // Fetch Remitly exchange rate from Remitly API
        const remitlyResponse = await axios.get(
          "https://proxy.cors.sh/https://api.remitly.io/v3/calculator/estimate?conduit=USA%3AUSD-IND%3AINR&anchor=SEND&amount=5000&purpose=OTHER&customer_segment=UNRECOGNIZED&strict_promo=false",
          {
            headers: {
              "x-cors-api-key": process.env.REACT_APP_CORS_API_KEY,
            },
          }
        );
        const remitlyData = remitlyResponse.data;
        // Fetch ofx exchange rate from ofx API
        const ofxResponse = await axios.get(
          "https://proxy.cors.sh/https://api.ofx.com/PublicSite.ApiService/OFX/spotrate/Individual/USD/INR/1000",
          {
            headers: {
              "x-cors-api-key": process.env.REACT_APP_CORS_API_KEY,
            },
          }
        );
        const ofxData = ofxResponse.data;
        const westernUnion = copperxData.find(
          (provider) => provider.alias === "western-union"
        );
        const instarem = copperxData.find(
          (provider) => provider.alias === "instarem"
        );
        const wise = copperxData.find((provider) => provider.alias === "wise");
        setExchangeRateData({
          westernUnion: westernUnion?.quotes[0]?.rate,
          wise: wiseData.value,
          remitly: remitlyData.estimate.exchange_rate.base_rate,
          instarem: instarem?.quotes[0]?.rate,
          ofx: ofxData.CustomerRate,
        });
        setTransferFeeData({
          westernUnion: westernUnion?.quotes[0]?.fee,
          wise: wise?.quotes[0]?.fee,
          remitly: Number(remitlyData.estimate.fee.total_fee_amount),
          instarem: instarem?.quotes[0]?.fee,
          ofx: ofxData.Fee,
        });
      } catch (error) {
        console.error("Error fetching exchange rates:", error);
      }
    };

    fetchExchangeRates();
  }, [THOUSAND]);

  // const isWesternUnionAvailable = () => {
  //   return Boolean(
  //     exchangeRateData?.westernUnion &&
  //       transferFeeData?.westernUnion !== undefined &&
  //       exchangeRateData.westernUnion !== 0
  //   );
  // };
  // const isInstaremAvailable = () => {
  //   return Boolean(
  //     exchangeRateData?.instarem &&
  //       transferFeeData?.instarem !== undefined &&
  //       exchangeRateData.instarem !== 0
  //   );
  // };

  return (
    <>
      {!isMobile && (
        <>
          <div className={styles.whyPayezyContainer}>
            <div className={styles.whyPayezy}>{SEND_ENUM.bestInMarket}</div>
            <div className={styles.lineContainer}></div>
            <div className={styles.features}>{SEND_ENUM.comparison}</div>
          </div>
          <div className={styles.liveGoogleRate}>
            Live google rate:{" "}
            {usdToInrExRate ? (
              `${usdToInrExRate.toFixed(2)} INR/USD`
            ) : (
              <Skeleton width={110} height={20} />
            )}
          </div>
          <div className={styles.featuresContainer}>
            <table className={styles.comparisonTable}>
              <thead>
                <tr>
                  <th>
                    <div>{SEND_ENUM.provider}</div>
                    <div className={styles.subHeading}></div>
                  </th>
                  <th>
                    <div>{SEND_ENUM.exchangeRate}</div>
                    <div className={styles.subHeading}>
                      {SEND_ENUM.USDToINR}
                    </div>
                  </th>
                  <th>
                    <div>{SEND_ENUM.transferFee}</div>
                    <div className={styles.subHeading}></div>
                  </th>
                  <th>
                    <div>{SEND_ENUM.totalCost}</div>
                    <div className={styles.subHeading}></div>
                  </th>
                  <th>
                    <div>{SEND_ENUM.recipientGets}</div>
                    <div className={styles.subHeading}>
                      {SEND_ENUM.sendingThousand}
                    </div>
                  </th>
                  <th>
                    <div>
                      {SEND_ENUM.trueRate}
                      <OverlayTrigger
                        placement="right"
                        overlay={renderTooltipTrueRate}
                      >
                        <img
                          src={ToolttipIcon}
                          className={styles.toolTipIcon}
                          alt="Tooltip"
                        />
                      </OverlayTrigger>
                    </div>
                    <div className={styles.subHeading}>
                      Effective Mid-Market Rate
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className={styles.payezyRow}>
                  <td>
                    <img
                      src={PayezyIcon}
                      className={styles.payezyImages}
                      alt="Payezy"
                    />
                  </td>
                  <td>
                    {usdToInrExRate ? (
                      <>
                        ₹ {usdToInrExRate.toFixed(TWO_FIXED_TWO)}
                        <OverlayTrigger
                          placement="right"
                          overlay={renderTooltipPayezy}
                        >
                          <img
                            src={ToolttipIcon}
                            className={styles.toolTipIcon}
                            alt="Tooltip"
                          />
                        </OverlayTrigger>
                      </>
                    ) : (
                      <Skeleton width={110} height={20} />
                    )}
                  </td>
                  <td>$ {PAYEZY_TRANSFER_FEE.toFixed(TWO_FIXED_TWO)}</td>
                  <td>
                    ${" "}
                    {calculateTotalCost(PAYEZY_TRANSFER_FEE).toFixed(
                      TWO_FIXED_TWO
                    )}
                  </td>
                  <td>
                    {usdToInrExRate ? (
                      <>
                        ₹{" "}
                        {(
                          usdToInrExRate * THOUSAND -
                          PAYEZY_TRANSFER_FEE
                        ).toFixed(TWO_FIXED_TWO)}
                      </>
                    ) : (
                      <Skeleton width={110} height={20} />
                    )}
                  </td>
                  <td>
                    {usdToInrExRate ? (
                      <>₹ {usdToInrExRate.toFixed(TWO_FIXED_TWO)}</>
                    ) : (
                      <Skeleton width={110} height={20} />
                    )}
                  </td>
                </tr>
                <tr className={styles.providerRow}>
                  <td className={styles.westernUnionProviderCell}>
                    <img
                      src={WesternUnionIcon}
                      className={styles.westernUnionImage}
                      alt="Western Union"
                    />
                  </td>
                  <td className={styles.westernUnionProviderCell}>
                    {exchangeRateData &&
                    transferFeeData &&
                    exchangeRateData.westernUnion ? (
                      <>
                        ₹ {exchangeRateData.westernUnion.toFixed(TWO_FIXED_TWO)}
                        <OverlayTrigger
                          placement="right"
                          overlay={renderTooltipWesternUnion}
                        >
                          <img
                            src={ToolttipIcon}
                            className={styles.toolTipIcon}
                            alt="Tooltip"
                          />
                        </OverlayTrigger>
                      </>
                    ) : (
                      <Skeleton width={110} height={20} />
                    )}
                  </td>
                  <td className={styles.westernUnionProviderCell}>
                    {exchangeRateData &&
                    transferFeeData &&
                    transferFeeData.westernUnion ? (
                      <>
                        $ {transferFeeData.westernUnion.toFixed(TWO_FIXED_TWO)}
                      </>
                    ) : (
                      <Skeleton width={110} height={20} />
                    )}
                  </td>
                  <td className={styles.westernUnionProviderCell}>
                    {exchangeRateData &&
                    transferFeeData &&
                    transferFeeData.westernUnion ? (
                      <>
                        ${" "}
                        {calculateTotalCost(
                          transferFeeData.westernUnion
                        ).toFixed(TWO_FIXED_TWO)}
                      </>
                    ) : (
                      <Skeleton width={110} height={20} />
                    )}
                  </td>
                  <td className={styles.westernUnionProviderCell}>
                    {exchangeRateData &&
                    transferFeeData &&
                    exchangeRateData.westernUnion ? (
                      <>
                        ₹{" "}
                        {calculateRecipientGetsValue(
                          exchangeRateData.westernUnion
                        )}
                        <div className={styles.priceDifference}>
                          <img
                            src={downArrow}
                            className={styles.downArrow}
                            alt="downArrow"
                          />
                          {(
                            calculateRecipientGetsValue(
                              exchangeRateData.westernUnion
                            ) -
                            (usdToInrExRate * THOUSAND - PAYEZY_TRANSFER_FEE)
                          ).toFixed(TWO_FIXED_TWO)}
                        </div>
                      </>
                    ) : (
                      <Skeleton width={110} height={20} />
                    )}
                  </td>
                  <td className={styles.westernUnionProviderCell}>
                    {exchangeRateData &&
                    transferFeeData &&
                    exchangeRateData.westernUnion &&
                    transferFeeData.westernUnion ? (
                      <>
                        ₹{" "}
                        {calculateTrueValue(
                          exchangeRateData.westernUnion,
                          calculateTotalCost(transferFeeData.westernUnion)
                        )}
                      </>
                    ) : (
                      <Skeleton width={110} height={20} />
                    )}
                  </td>
                </tr>
                <tr className={styles.providerRow}>
                  <td>
                    <img
                      src={WiselyIcon}
                      className={styles.wiseImage}
                      alt="Wisely"
                    />
                  </td>
                  <td className={styles.providerCell}>
                    {exchangeRateData &&
                    transferFeeData &&
                    exchangeRateData.wise ? (
                      <>
                        ₹ {exchangeRateData.wise.toFixed(TWO_FIXED_TWO)}
                        <OverlayTrigger
                          placement="right"
                          overlay={renderTooltipWise}
                        >
                          <img
                            src={ToolttipIcon}
                            className={styles.toolTipIcon}
                            alt="Tooltip"
                          />
                        </OverlayTrigger>
                      </>
                    ) : (
                      <Skeleton width={110} height={20} />
                    )}
                  </td>
                  <td className={styles.providerCell}>
                    {exchangeRateData &&
                    transferFeeData &&
                    transferFeeData.wise ? (
                      <>$ {transferFeeData.wise.toFixed(TWO_FIXED_TWO)}</>
                    ) : (
                      <Skeleton width={110} height={20} />
                    )}
                  </td>
                  <td className={styles.providerCell}>
                    {exchangeRateData &&
                    transferFeeData &&
                    transferFeeData.wise ? (
                      <>
                        ${" "}
                        {calculateTotalCost(transferFeeData.wise).toFixed(
                          TWO_FIXED_TWO
                        )}
                      </>
                    ) : (
                      <Skeleton width={110} height={20} />
                    )}
                  </td>
                  <td className={styles.providerCell}>
                    {exchangeRateData &&
                    transferFeeData &&
                    exchangeRateData.wise ? (
                      <>
                        ₹ {calculateRecipientGetsValue(exchangeRateData.wise)}
                        <div className={styles.priceDifference}>
                          <img
                            src={downArrow}
                            className={styles.downArrow}
                            alt="downArrow"
                          />
                          {(
                            calculateRecipientGetsValue(exchangeRateData.wise) -
                            (usdToInrExRate * THOUSAND - PAYEZY_TRANSFER_FEE)
                          ).toFixed(TWO_FIXED_TWO)}
                        </div>
                      </>
                    ) : (
                      <Skeleton width={110} height={20} />
                    )}
                  </td>

                  <td className={styles.providerCell}>
                    {exchangeRateData &&
                    transferFeeData &&
                    exchangeRateData.wise &&
                    transferFeeData.wise ? (
                      <>
                        ₹{" "}
                        {calculateTrueValue(
                          exchangeRateData.wise,
                          calculateTotalCost(transferFeeData.wise)
                        )}
                      </>
                    ) : (
                      <Skeleton width={110} height={20} />
                    )}
                  </td>
                </tr>
                <tr className={styles.providerRow}>
                  <td>
                    <img
                      src={RemitlyIcon}
                      className={styles.remitlyImage}
                      alt="Remitly"
                    />
                  </td>
                  <td className={styles.providerCell}>
                    {exchangeRateData &&
                    transferFeeData &&
                    exchangeRateData.remitly ? (
                      <>
                        ₹ {exchangeRateData.remitly}
                        <OverlayTrigger
                          placement="right"
                          overlay={renderTooltipRemitely}
                        >
                          <img
                            src={ToolttipIcon}
                            className={styles.toolTipIcon}
                            alt="Tooltip"
                          />
                        </OverlayTrigger>
                      </>
                    ) : (
                      <Skeleton width={110} height={20} />
                    )}
                  </td>
                  <td className={styles.providerCell}>
                    {exchangeRateData && transferFeeData ? (
                      <>
                        ${" "}
                        {transferFeeData.remitly
                          ? transferFeeData.remitly.toFixed(TWO_FIXED_TWO)
                          : "0.00"}
                      </>
                    ) : (
                      <Skeleton width={110} height={20} />
                    )}
                  </td>
                  <td className={styles.providerCell}>
                    {exchangeRateData && transferFeeData ? (
                      <>
                        ${" "}
                        {calculateTotalCost(transferFeeData.remitly).toFixed(
                          TWO_FIXED_TWO
                        )}
                      </>
                    ) : (
                      <Skeleton width={110} height={20} />
                    )}
                  </td>
                  <td className={styles.providerCell}>
                    {exchangeRateData &&
                    transferFeeData &&
                    exchangeRateData.remitly ? (
                      <>
                        ₹{" "}
                        {calculateRecipientGetsValue(exchangeRateData.remitly)}
                        <div className={styles.priceDifference}>
                          <img
                            src={downArrow}
                            className={styles.downArrow}
                            alt="downArrow"
                          />
                          {(
                            calculateRecipientGetsValue(
                              exchangeRateData.remitly
                            ) -
                            (usdToInrExRate * THOUSAND - PAYEZY_TRANSFER_FEE)
                          ).toFixed(TWO_FIXED_TWO)}
                        </div>
                      </>
                    ) : (
                      <Skeleton width={110} height={20} />
                    )}
                  </td>

                  <td className={styles.providerCell}>
                    {exchangeRateData &&
                    transferFeeData &&
                    exchangeRateData.remitly ? (
                      <>
                        ₹{" "}
                        {calculateTrueValue(
                          exchangeRateData.remitly,
                          calculateTotalCost(transferFeeData.remitly)
                        )}
                      </>
                    ) : (
                      <Skeleton width={110} height={20} />
                    )}
                  </td>
                </tr>
                <tr className={styles.providerRow}>
                  <td>
                    <img
                      src={InstaremIcon}
                      className={styles.remitlyImage}
                      alt="Instarem"
                    />
                  </td>
                  <td className={styles.providerCell}>
                    {exchangeRateData &&
                    transferFeeData &&
                    exchangeRateData.instarem ? (
                      <>
                        ₹ {exchangeRateData.instarem.toFixed(TWO_FIXED_TWO)}
                        <OverlayTrigger
                          placement="right"
                          overlay={renderTooltipInstarem}
                        >
                          <img
                            src={ToolttipIcon}
                            className={styles.toolTipIcon}
                            alt="Tooltip"
                          />
                        </OverlayTrigger>
                      </>
                    ) : (
                      <Skeleton width={110} height={20} />
                    )}
                  </td>
                  <td className={styles.providerCell}>
                    {exchangeRateData && transferFeeData ? (
                      <>
                        ${" "}
                        {transferFeeData.instarem
                          ? transferFeeData.instarem.toFixed(TWO_FIXED_TWO)
                          : "0.00"}
                      </>
                    ) : (
                      <Skeleton width={110} height={20} />
                    )}
                  </td>
                  <td className={styles.providerCell}>
                    {exchangeRateData && transferFeeData ? (
                      <>
                        ${" "}
                        {calculateTotalCost(transferFeeData.instarem).toFixed(
                          TWO_FIXED_TWO
                        )}
                      </>
                    ) : (
                      <Skeleton width={110} height={20} />
                    )}
                  </td>
                  <td className={styles.providerCell}>
                    {exchangeRateData &&
                    transferFeeData &&
                    exchangeRateData.instarem ? (
                      <>
                        ₹{" "}
                        {calculateRecipientGetsValue(exchangeRateData.instarem)}
                        <div className={styles.priceDifference}>
                          <img
                            src={downArrow}
                            className={styles.downArrow}
                            alt="downArrow"
                          />
                          {(
                            calculateRecipientGetsValue(
                              exchangeRateData.instarem
                            ) -
                            (usdToInrExRate * THOUSAND - PAYEZY_TRANSFER_FEE)
                          ).toFixed(TWO_FIXED_TWO)}
                        </div>
                      </>
                    ) : (
                      <Skeleton width={110} height={20} />
                    )}
                  </td>

                  <td className={styles.providerCell}>
                    {exchangeRateData &&
                    transferFeeData &&
                    exchangeRateData.instarem ? (
                      <>
                        ₹{" "}
                        {calculateTrueValue(
                          exchangeRateData.instarem,
                          calculateTotalCost(transferFeeData.instarem)
                        )}
                      </>
                    ) : (
                      <Skeleton width={110} height={20} />
                    )}
                  </td>
                </tr>
                <tr className={styles.providerRow}>
                  <td>
                    <img src={ofxIcon} className={styles.ofxIcon} alt="OFX" />
                  </td>
                  <td className={styles.providerCell}>
                    {exchangeRateData &&
                    transferFeeData &&
                    exchangeRateData.ofx ? (
                      <>
                        ₹ {exchangeRateData.ofx.toFixed(TWO_FIXED_TWO)}
                        <OverlayTrigger
                          placement="right"
                          overlay={renderTooltipOfx}
                        >
                          <img
                            src={ToolttipIcon}
                            className={styles.toolTipIcon}
                            alt="Tooltip"
                          />
                        </OverlayTrigger>
                      </>
                    ) : (
                      <Skeleton width={110} height={20} />
                    )}
                  </td>
                  <td className={styles.providerCell}>
                    {exchangeRateData && transferFeeData ? (
                      <>
                        ${" "}
                        {transferFeeData.ofx
                          ? transferFeeData.ofx.toFixed(TWO_FIXED_TWO)
                          : "0.00"}
                      </>
                    ) : (
                      <Skeleton width={110} height={20} />
                    )}
                  </td>
                  <td className={styles.providerCell}>
                    {exchangeRateData && transferFeeData ? (
                      <>
                        ${" "}
                        {calculateTotalCost(transferFeeData.ofx).toFixed(
                          TWO_FIXED_TWO
                        )}
                      </>
                    ) : (
                      <Skeleton width={110} height={20} />
                    )}
                  </td>
                  <td className={styles.providerCell}>
                    {exchangeRateData &&
                    transferFeeData &&
                    exchangeRateData.ofx ? (
                      <>
                        ₹ {calculateRecipientGetsValue(exchangeRateData.ofx)}
                        <div className={styles.priceDifference}>
                          <img
                            src={downArrow}
                            className={styles.downArrow}
                            alt="downArrow"
                          />
                          {(
                            calculateRecipientGetsValue(exchangeRateData.ofx) -
                            (usdToInrExRate * THOUSAND - PAYEZY_TRANSFER_FEE)
                          ).toFixed(TWO_FIXED_TWO)}
                        </div>
                      </>
                    ) : (
                      <Skeleton width={110} height={20} />
                    )}
                  </td>

                  <td className={styles.providerCell}>
                    {exchangeRateData &&
                    transferFeeData &&
                    exchangeRateData.ofx ? (
                      <>
                        ₹{" "}
                        {calculateTrueValue(
                          exchangeRateData.ofx,
                          calculateTotalCost(transferFeeData.ofx)
                        )}
                      </>
                    ) : (
                      <Skeleton width={110} height={20} />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
      {isMobile && (
        <>
          <div className={styles.whyPayezyContainer}>
            <div className={styles.whyPayezyMob}>{SEND_ENUM.bestInMarket}</div>

            <div className={styles.comparisonMob}>{SEND_ENUM.comparison}</div>
            <div className={styles.liveGoogleRateMob}>
              Live google rate:{" "}
              {usdToInrExRate ? (
                `${usdToInrExRate.toFixed(2)} INR/USD`
              ) : (
                <Skeleton width={100} height={15} />
              )}
            </div>
          </div>
          <div className={styles.priceComaprisonContainer}>
            {" "}
            <div className={styles.priceComparisonMainContainer}>
              <div className={styles.payezyImageMobContainer}>
                <img src={PayezyIcon} className={styles.payezyImageMob} />
              </div>
              <div className={styles.priceComparisonSubContainer}>
                <div>
                  <div>
                    <div>
                      <div className={styles.exchangeRateInMob}>
                        {SEND_ENUM.exchangeRate}
                      </div>
                      <div className={styles.USDToINRInamob}>
                        {SEND_ENUM.USDToINR}
                      </div>
                      <div className={styles.exchangeRateInMob}>
                        {usdToInrExRate ? (
                          <>
                            ₹ {usdToInrExRate.toFixed(TWO_FIXED_TWO)}
                            <OverlayTrigger
                              placement="right"
                              overlay={renderTooltipPayezy}
                            >
                              <img
                                src={ToolttipIcon}
                                className={styles.toolTipIcon}
                                alt="Tooltip"
                              />
                            </OverlayTrigger>
                          </>
                        ) : (
                          <Skeleton width={80} height={20} />
                        )}
                      </div>
                      <div className={styles.recipientGetsInMob}>
                        {SEND_ENUM.totalCost}
                      </div>
                      <div className={styles.USDToINRInamob}>
                        {SEND_ENUM.sendingThousand}
                      </div>
                      <div className={styles.exchangeRateInMob}>
                        ${" "}
                        {calculateTotalCost(PAYEZY_TRANSFER_FEE).toFixed(
                          TWO_FIXED_TWO
                        )}
                      </div>
                      <div className={styles.trueRateInMob}>
                        True Rate{" "}
                        <OverlayTrigger
                          placement="right"
                          overlay={renderTooltipTrueRate}
                        >
                          <img
                            src={ToolttipIcon}
                            className={styles.toolTipIcon}
                          />
                        </OverlayTrigger>
                      </div>
                      <div className={styles.USDToINRInamob}>
                        Effective Mid-Market Rate
                      </div>
                      <div className={styles.exchangeRateInMob}>
                        {usdToInrExRate ? (
                          <>₹ {usdToInrExRate.toFixed(TWO_FIXED_TWO)}</>
                        ) : (
                          <Skeleton width={80} height={20} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div>
                      <div className={styles.transferRateInMob}>
                        {SEND_ENUM.transferFee}
                      </div>
                      <div className={styles.exchangeRateInMob}>
                        $ {PAYEZY_TRANSFER_FEE.toFixed(TWO_FIXED_TWO)}
                      </div>
                      <div className={styles.recipientGetsInMob}>
                        {SEND_ENUM.recipientGets}
                      </div>
                      <div className={styles.USDToINRInamob}>
                        {SEND_ENUM.sendingThousand}
                      </div>
                      <div className={styles.exchangeRateInMob}>
                        {usdToInrExRate ? (
                          <>
                            ₹{" "}
                            {(
                              usdToInrExRate * THOUSAND -
                              PAYEZY_TRANSFER_FEE
                            ).toFixed(TWO_FIXED_TWO)}
                          </>
                        ) : (
                          <Skeleton width={80} height={20} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.priceComparisonMainContainer}>
              <div className={styles.payezyImageMobContainer}>
                <img
                  src={WesternUnionIcon}
                  className={styles.westernUnionImageMob}
                />
              </div>
              <div className={styles.priceComparisonSubContainer}>
                <div>
                  <div>
                    <div>
                      <div className={styles.exchangeRateInMob}>
                        {SEND_ENUM.exchangeRate}
                      </div>
                      <div className={styles.USDToINRInamob}>
                        {SEND_ENUM.USDToINR}
                      </div>
                      <div className={styles.exchangeRateNotPayezyInMob}>
                        {exchangeRateData &&
                        transferFeeData &&
                        exchangeRateData.westernUnion ? (
                          <>
                            ₹{" "}
                            {exchangeRateData.westernUnion.toFixed(
                              TWO_FIXED_TWO
                            )}
                            <OverlayTrigger
                              placement="right"
                              overlay={renderTooltipWesternUnion}
                            >
                              <img
                                src={ToolttipIcon}
                                className={styles.toolTipIcon}
                                alt="Tooltip"
                              />
                            </OverlayTrigger>
                          </>
                        ) : (
                          <Skeleton width={80} height={20} />
                        )}
                      </div>
                      <div className={styles.recipientGetsInMob}>
                        {SEND_ENUM.totalCost}
                      </div>
                      <div className={styles.USDToINRInamob}>
                        {SEND_ENUM.sendingThousand}
                      </div>
                      <div className={styles.exchangeRateNotPayezyInMob}>
                        {exchangeRateData &&
                        transferFeeData &&
                        transferFeeData.westernUnion ? (
                          <>
                            ${" "}
                            {calculateTotalCost(
                              transferFeeData.westernUnion
                            ).toFixed(TWO_FIXED_TWO)}
                          </>
                        ) : (
                          <Skeleton width={80} height={20} />
                        )}
                      </div>
                      <div className={styles.trueRateInMob}>True Rate</div>
                      <div className={styles.USDToINRInamob}>
                        Effective Mid-Market Rate
                      </div>
                      <div className={styles.exchangeRateNotPayezyInMob}>
                        {exchangeRateData &&
                        transferFeeData &&
                        exchangeRateData.westernUnion &&
                        transferFeeData.westernUnion ? (
                          <>
                            ₹{" "}
                            {calculateTrueValue(
                              exchangeRateData.westernUnion,
                              calculateTotalCost(transferFeeData.westernUnion)
                            )}
                          </>
                        ) : (
                          <Skeleton width={80} height={20} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div>
                      <div className={styles.transferRateInMob}>
                        {SEND_ENUM.transferFee}
                      </div>

                      <div className={styles.exchangeRateNotPayezyInMob}>
                        {exchangeRateData &&
                        transferFeeData &&
                        transferFeeData.westernUnion ? (
                          <>
                            ${" "}
                            {transferFeeData.westernUnion.toFixed(
                              TWO_FIXED_TWO
                            )}
                          </>
                        ) : (
                          <Skeleton width={80} height={20} />
                        )}
                      </div>
                      <div className={styles.recipientGetsInMob}>
                        {SEND_ENUM.recipientGets}
                      </div>
                      <div className={styles.USDToINRInamob}>
                        {SEND_ENUM.sendingThousand}
                      </div>
                      <div className={styles.exchangeRateNotPayezyInMob}>
                        {exchangeRateData &&
                        transferFeeData &&
                        exchangeRateData.westernUnion ? (
                          <>
                            ₹{" "}
                            {calculateRecipientGetsValue(
                              exchangeRateData.westernUnion
                            )}
                          </>
                        ) : (
                          <Skeleton width={80} height={20} />
                        )}
                      </div>
                    </div>
                    <span className={styles.priceDifferenceInMob}>
                      {exchangeRateData &&
                      transferFeeData &&
                      exchangeRateData.westernUnion ? (
                        <>
                          <img
                            src={downArrow}
                            className={styles.downArrow}
                            alt="downArrow"
                          />
                          {(
                            calculateRecipientGetsValue(
                              exchangeRateData.westernUnion
                            ) -
                            (usdToInrExRate * THOUSAND - PAYEZY_TRANSFER_FEE)
                          ).toFixed(TWO_FIXED_TWO)}
                        </>
                      ) : (
                        <Skeleton width={80} height={20} />
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.priceComparisonMainContainer}>
              <div className={styles.payezyImageMobContainer}>
                <img src={WiselyIcon} className={styles.wiseLyImageMob} />
              </div>
              <div className={styles.priceComparisonSubContainer}>
                <div>
                  <div>
                    <div>
                      <div className={styles.exchangeRateInMob}>
                        {SEND_ENUM.exchangeRate}
                      </div>
                      <div className={styles.USDToINRInamob}>
                        {SEND_ENUM.USDToINR}
                      </div>
                      <div className={styles.exchangeRateNotPayezyInMob}>
                        {exchangeRateData &&
                        transferFeeData &&
                        exchangeRateData.wise ? (
                          <>
                            ₹ {exchangeRateData.wise.toFixed(TWO_FIXED_TWO)}
                            <OverlayTrigger
                              placement="right"
                              overlay={renderTooltipWise}
                            >
                              <img
                                src={ToolttipIcon}
                                className={styles.toolTipIcon}
                                alt="Tooltip"
                              />
                            </OverlayTrigger>
                          </>
                        ) : (
                          <Skeleton width={80} height={20} />
                        )}
                      </div>
                      <div className={styles.recipientGetsInMob}>
                        {SEND_ENUM.totalCost}
                      </div>
                      <div className={styles.USDToINRInamob}>
                        {SEND_ENUM.sendingThousand}
                      </div>
                      <div className={styles.exchangeRateNotPayezyInMob}>
                        {exchangeRateData &&
                        transferFeeData &&
                        transferFeeData.wise ? (
                          <>
                            ${" "}
                            {calculateTotalCost(transferFeeData.wise).toFixed(
                              TWO_FIXED_TWO
                            )}
                          </>
                        ) : (
                          <Skeleton width={80} height={20} />
                        )}
                      </div>
                      <div className={styles.trueRateInMob}>True Rate</div>
                      <div className={styles.USDToINRInamob}>
                        Effective Mid-Market Rate
                      </div>
                      <div className={styles.exchangeRateNotPayezyInMob}>
                        {exchangeRateData &&
                        transferFeeData &&
                        exchangeRateData.wise &&
                        transferFeeData.wise ? (
                          <>
                            ₹{" "}
                            {calculateTrueValue(
                              exchangeRateData.wise,
                              calculateTotalCost(transferFeeData.wise)
                            )}
                          </>
                        ) : (
                          <Skeleton width={80} height={20} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div>
                      <div className={styles.transferRateInMob}>
                        {SEND_ENUM.transferFee}
                      </div>

                      <div className={styles.exchangeRateNotPayezyInMob}>
                        {exchangeRateData &&
                        transferFeeData &&
                        transferFeeData.wise ? (
                          <>$ {transferFeeData.wise.toFixed(TWO_FIXED_TWO)}</>
                        ) : (
                          <Skeleton width={80} height={20} />
                        )}
                      </div>
                      <div className={styles.recipientGetsInMob}>
                        {SEND_ENUM.recipientGets}
                      </div>
                      <div className={styles.USDToINRInamob}>
                        {SEND_ENUM.sendingThousand}
                      </div>
                      <div className={styles.exchangeRateNotPayezyInMob}>
                        {exchangeRateData &&
                        transferFeeData &&
                        exchangeRateData.wise ? (
                          <>
                            ₹{" "}
                            {calculateRecipientGetsValue(exchangeRateData.wise)}
                          </>
                        ) : (
                          <Skeleton width={80} height={20} />
                        )}
                      </div>
                    </div>
                    <span className={styles.priceDifferenceInMob}>
                      {exchangeRateData &&
                      transferFeeData &&
                      exchangeRateData.wise ? (
                        <>
                          <img
                            src={downArrow}
                            className={styles.downArrow}
                            alt="downArrow"
                          />
                          {(
                            calculateRecipientGetsValue(exchangeRateData.wise) -
                            (usdToInrExRate * THOUSAND - PAYEZY_TRANSFER_FEE)
                          ).toFixed(TWO_FIXED_TWO)}
                        </>
                      ) : (
                        <Skeleton width={80} height={20} />
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.priceComparisonMainContainer}>
              <div className={styles.payezyImageMobContainer}>
                <img src={RemitlyIcon} className={styles.remitlyIconMob} />
              </div>
              <div className={styles.priceComparisonSubContainer}>
                <div>
                  <div>
                    <div>
                      <div className={styles.exchangeRateInMob}>
                        {SEND_ENUM.exchangeRate}
                      </div>
                      <div className={styles.USDToINRInamob}>
                        {SEND_ENUM.USDToINR}
                      </div>
                      <div className={styles.exchangeRateNotPayezyInMob}>
                        {exchangeRateData &&
                        transferFeeData &&
                        exchangeRateData.remitly ? (
                          <>
                            ₹ {exchangeRateData.remitly}
                            <OverlayTrigger
                              placement="right"
                              overlay={renderTooltipRemitely}
                            >
                              <img
                                src={ToolttipIcon}
                                className={styles.toolTipIcon}
                                alt="Tooltip"
                              />
                            </OverlayTrigger>
                          </>
                        ) : (
                          <Skeleton width={80} height={20} />
                        )}
                      </div>
                      <div className={styles.recipientGetsInMob}>
                        {SEND_ENUM.totalCost}
                      </div>
                      <div className={styles.USDToINRInamob}>
                        {SEND_ENUM.sendingThousand}
                      </div>
                      <div className={styles.exchangeRateNotPayezyInMob}>
                        {exchangeRateData && transferFeeData ? (
                          <>
                            ${" "}
                            {calculateTotalCost(
                              transferFeeData.remitly
                            ).toFixed(TWO_FIXED_TWO)}
                          </>
                        ) : (
                          <Skeleton width={80} height={20} />
                        )}
                      </div>
                      <div className={styles.trueRateInMob}>True Rate</div>
                      <div className={styles.USDToINRInamob}>
                        Effective Mid-Market Rate
                      </div>
                      <div className={styles.exchangeRateNotPayezyInMob}>
                        {exchangeRateData &&
                        transferFeeData &&
                        exchangeRateData.remitly ? (
                          <>
                            ₹{" "}
                            {calculateTrueValue(
                              exchangeRateData.remitly,
                              calculateTotalCost(transferFeeData.remitly)
                            )}
                          </>
                        ) : (
                          <Skeleton width={80} height={20} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div>
                      <div className={styles.transferRateInMob}>
                        {SEND_ENUM.transferFee}
                      </div>

                      <div className={styles.exchangeRateNotPayezyInMob}>
                        {exchangeRateData && transferFeeData ? (
                          <>
                            ${" "}
                            {transferFeeData.remitly
                              ? transferFeeData.remitly.toFixed(TWO_FIXED_TWO)
                              : "0.00"}
                          </>
                        ) : (
                          <Skeleton width={80} height={20} />
                        )}
                      </div>
                      <div className={styles.recipientGetsInMob}>
                        {SEND_ENUM.recipientGets}
                      </div>
                      <div className={styles.USDToINRInamob}>
                        {SEND_ENUM.sendingThousand}
                      </div>
                      <div className={styles.exchangeRateNotPayezyInMob}>
                        {exchangeRateData &&
                        transferFeeData &&
                        exchangeRateData.remitly ? (
                          <>
                            ₹{" "}
                            {calculateRecipientGetsValue(
                              exchangeRateData.remitly
                            )}
                          </>
                        ) : (
                          <Skeleton width={80} height={20} />
                        )}
                      </div>
                    </div>
                    <span className={styles.priceDifferenceInMob}>
                      {exchangeRateData &&
                      transferFeeData &&
                      exchangeRateData.remitly ? (
                        <>
                          <img
                            src={downArrow}
                            className={styles.downArrow}
                            alt="downArrow"
                          />
                          {(
                            calculateRecipientGetsValue(
                              exchangeRateData.remitly
                            ) -
                            (usdToInrExRate * THOUSAND - PAYEZY_TRANSFER_FEE)
                          ).toFixed(TWO_FIXED_TWO)}
                        </>
                      ) : (
                        <Skeleton width={80} height={20} />
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.priceComparisonMainContainer}>
              <div className={styles.payezyImageMobContainer}>
                <img src={InstaremIcon} className={styles.instaremImageMob} />
              </div>
              <div className={styles.priceComparisonSubContainer}>
                <div>
                  <div>
                    <div>
                      <div className={styles.exchangeRateInMob}>
                        {SEND_ENUM.exchangeRate}
                      </div>
                      <div className={styles.USDToINRInamob}>
                        {SEND_ENUM.USDToINR}
                      </div>
                      <div className={styles.exchangeRateNotPayezyInMob}>
                        {exchangeRateData &&
                        transferFeeData &&
                        exchangeRateData.instarem ? (
                          <>
                            ₹ {exchangeRateData.instarem.toFixed(TWO_FIXED_TWO)}
                            <OverlayTrigger
                              placement="right"
                              overlay={renderTooltipInstarem}
                            >
                              <img
                                src={ToolttipIcon}
                                className={styles.toolTipIcon}
                                alt="Tooltip"
                              />
                            </OverlayTrigger>
                          </>
                        ) : (
                          <Skeleton width={80} height={20} />
                        )}
                      </div>
                      <div className={styles.recipientGetsInMob}>
                        {SEND_ENUM.totalCost}
                      </div>
                      <div className={styles.USDToINRInamob}>
                        {SEND_ENUM.sendingThousand}
                      </div>
                      <div className={styles.exchangeRateNotPayezyInMob}>
                        {exchangeRateData && transferFeeData ? (
                          <>
                            ${" "}
                            {calculateTotalCost(
                              transferFeeData.instarem
                            ).toFixed(TWO_FIXED_TWO)}
                          </>
                        ) : (
                          <Skeleton width={80} height={20} />
                        )}
                      </div>
                      <div className={styles.trueRateInMob}>True Rate</div>
                      <div className={styles.USDToINRInamob}>
                        Effective Mid-Market Rate
                      </div>
                      <div className={styles.exchangeRateNotPayezyInMob}>
                        {exchangeRateData &&
                        transferFeeData &&
                        exchangeRateData.instarem ? (
                          <>
                            ₹{" "}
                            {calculateTrueValue(
                              exchangeRateData.instarem,
                              calculateTotalCost(transferFeeData.instarem)
                            )}
                          </>
                        ) : (
                          <Skeleton width={80} height={20} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div>
                      <div className={styles.transferRateInMob}>
                        {SEND_ENUM.transferFee}
                      </div>

                      <div className={styles.exchangeRateNotPayezyInMob}>
                        {exchangeRateData && transferFeeData ? (
                          <>
                            ${" "}
                            {transferFeeData.instarem
                              ? transferFeeData.instarem.toFixed(TWO_FIXED_TWO)
                              : "0.00"}
                          </>
                        ) : (
                          <Skeleton width={80} height={20} />
                        )}
                      </div>
                      <div className={styles.recipientGetsInMob}>
                        {SEND_ENUM.recipientGets}
                      </div>
                      <div className={styles.USDToINRInamob}>
                        {SEND_ENUM.sendingThousand}
                      </div>
                      <div className={styles.exchangeRateNotPayezyInMob}>
                        {exchangeRateData &&
                        transferFeeData &&
                        exchangeRateData.instarem ? (
                          <>
                            ₹{" "}
                            {calculateRecipientGetsValue(
                              exchangeRateData.instarem
                            )}
                          </>
                        ) : (
                          <Skeleton width={80} height={20} />
                        )}
                      </div>
                    </div>
                    <span className={styles.priceDifferenceInMob}>
                      {exchangeRateData &&
                      transferFeeData &&
                      exchangeRateData.instarem ? (
                        <>
                          <img
                            src={downArrow}
                            className={styles.downArrow}
                            alt="downArrow"
                          />
                          {(
                            calculateRecipientGetsValue(
                              exchangeRateData.instarem
                            ) -
                            (usdToInrExRate * THOUSAND - PAYEZY_TRANSFER_FEE)
                          ).toFixed(TWO_FIXED_TWO)}
                        </>
                      ) : (
                        <Skeleton width={80} height={20} />
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.priceComparisonMainContainer}>
              <div className={styles.payezyImageMobContainer}>
                <img src={ofxIcon} className={styles.ofxIconMob} />
              </div>
              <div className={styles.priceComparisonSubContainerInstarem}>
                <div>
                  <div>
                    <div>
                      <div className={styles.exchangeRateInMob}>
                        {SEND_ENUM.exchangeRate}
                      </div>
                      <div className={styles.USDToINRInamob}>
                        {SEND_ENUM.USDToINR}
                      </div>
                      <div className={styles.exchangeRateNotPayezyInMob}>
                        {exchangeRateData &&
                        transferFeeData &&
                        exchangeRateData.ofx ? (
                          <>
                            ₹ {exchangeRateData.ofx.toFixed(TWO_FIXED_TWO)}
                            <OverlayTrigger
                              placement="right"
                              overlay={renderTooltipOfx}
                            >
                              <img
                                src={ToolttipIcon}
                                className={styles.toolTipIcon}
                                alt="Tooltip"
                              />
                            </OverlayTrigger>
                          </>
                        ) : (
                          <Skeleton width={80} height={20} />
                        )}
                      </div>
                      <div className={styles.recipientGetsInMob}>
                        {SEND_ENUM.totalCost}
                      </div>
                      <div className={styles.USDToINRInamob}>
                        {SEND_ENUM.sendingThousand}
                      </div>
                      <div className={styles.exchangeRateNotPayezyInMob}>
                        {exchangeRateData && transferFeeData ? (
                          <>
                            ${" "}
                            {calculateTotalCost(transferFeeData.ofx).toFixed(
                              TWO_FIXED_TWO
                            )}
                          </>
                        ) : (
                          <Skeleton width={80} height={20} />
                        )}
                      </div>
                      <div className={styles.trueRateInMob}>True Rate</div>
                      <div className={styles.USDToINRInamob}>
                        Effective Mid-Market Rate
                      </div>
                      <div className={styles.exchangeRateNotPayezyInMob}>
                        {exchangeRateData &&
                        transferFeeData &&
                        exchangeRateData.ofx ? (
                          <>
                            ₹{" "}
                            {calculateTrueValue(
                              exchangeRateData.ofx,
                              calculateTotalCost(transferFeeData.ofx)
                            )}
                          </>
                        ) : (
                          <Skeleton width={80} height={20} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div>
                      <div className={styles.transferRateInMob}>
                        {SEND_ENUM.transferFee}
                      </div>

                      <div className={styles.exchangeRateNotPayezyInMob}>
                        {exchangeRateData && transferFeeData ? (
                          <>
                            ${" "}
                            {transferFeeData.ofx
                              ? transferFeeData.ofx.toFixed(TWO_FIXED_TWO)
                              : "0.00"}
                          </>
                        ) : (
                          <Skeleton width={80} height={20} />
                        )}
                      </div>
                      <div className={styles.recipientGetsInMob}>
                        {SEND_ENUM.recipientGets}
                      </div>
                      <div className={styles.USDToINRInamob}>
                        {SEND_ENUM.sendingThousand}
                      </div>
                      <div className={styles.exchangeRateNotPayezyInMob}>
                        {exchangeRateData &&
                        transferFeeData &&
                        exchangeRateData.ofx ? (
                          <>
                            ₹{" "}
                            {calculateRecipientGetsValue(exchangeRateData.ofx)}
                          </>
                        ) : (
                          <Skeleton width={80} height={20} />
                        )}
                      </div>
                    </div>
                    <span className={styles.priceDifferenceInMob}>
                      {exchangeRateData &&
                      transferFeeData &&
                      exchangeRateData.ofx ? (
                        <>
                          <img
                            src={downArrow}
                            className={styles.downArrow}
                            alt="downArrow"
                          />
                          {(
                            calculateRecipientGetsValue(exchangeRateData.ofx) -
                            (usdToInrExRate * THOUSAND - PAYEZY_TRANSFER_FEE)
                          ).toFixed(TWO_FIXED_TWO)}
                        </>
                      ) : (
                        <Skeleton width={80} height={20} />
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BestInMarket;
